import Emitter from "./Emitter";
import mqtt from 'mqtt'
import $store from '../store'
export default class {
    constructor(connection) {
            this.options = connection;
            this.mqtt = mqtt.connect(`ws://${connection.host}:${connection.port}${connection.endpoint}`, connection)
            this.mqtt.on('connect', (err) => {
                    // console.log(err || '连接成功');
                    //console.log(res);
                    // $store.commit('updateMaskStatus', false)
                    // console.log(`${process.env.VUE_APP_COMPANY}/+/robot_com_qr`)
                    this.mqtt.subscribe([`scrm_user/${connection.clientId}/#`, `${connection.company}/${connection.clientId}/#`, `${connection.company}/+/robot_com_qr`, `${connection.company}/+/robot_com_bind`, `scrm_user/+/memory`, `${connection.company}/+/receive`, `${connection.company}/+/getQR`, `${connection.company}/+/cardmsg`, `${connection.company}/+/login`,`${connection.company}/+/login_verify`], {
                        qos: connection.qos
                    }, (error, res) => {
                        // console.log(error || '订阅成功');
                        $store.commit('syncMqttConnect', true)
                    })
                }).on('reconnect', (err) => {
                    // console.log('正在重连:', err)
                }).on('offline', (err) => {
                    // console.log('连接断开:', err)
                }).on('close', (err) => {
                    // $store.commit('updateMaskStatus', true)
                    // console.log('关闭了', err)
                }).on('end', (err) => {
                    // $store.commit('syncMqttConnect', false)
                    // console.log('end:', err)
                }).on('disconnect', (err) => {
                    // console.log('disconnect', err)
                }).on('error', (err) => {
                    // console.log('error', err)
                })
                .on('message', (topic, message) => {
                    // console.log(`${topic}值${message}`)
                    let data = message.toString();
                    try {
                        data = JSON.parse(data);
                    } catch {
                        //console.error(e);
                    }
                    let label = topic.split("/").pop();
                    if (label) {
                        // console.log(`收到消息，回调ID: ${label} 数据内容：`, typeof data === "string" ? [data.substring(0, 50)] : data, topic);
                        Emitter.emit(label, data, topic);
                    }
                })

        }
        // 发布时注册订阅的回调
    doPublish(params, callback, vm) {
        if (!this.mqtt.connected) {
            this.$message.error('您的网络好像开小差了！！！')
            return;
        }
        const msgId = "callback_" + Math.random().toString(16).substr(2, 8) + "_" + new Date().getTime().toString(16);
        const {
            pubTopic,
            payload
        } = params;
        let company = lockr.get('company')
        payload.clientId ? "" : payload.clientId = this.options.clientId;
        // payload.clientId = 'chenzhe'
        payload.data && (payload.data.company ? "" : payload.data.company = (this.options.company || company))
        payload.token ? "" : payload.token = this.options.token;
        payload.msgId ? "" : payload.msgId = msgId;
        // console.log(`payload ${JSON.stringify(payload)} to ${pubTopic}`)
        Emitter.addListener(payload.msgId, callback, vm, params);
        this.mqtt.publish(pubTopic, JSON.stringify(payload), {
            qos: this.options.qos
        }, (error) => {
            //console.log('发布主题：', pubTopic)
            if (error) {
                console.log('发布失败：', error);
                Emitter.removeListener(payload.msgId, callback, vm);
            }
            // console.log(`发布成功，回调ID:${payload.msgId}, topic：${pubTopic}，发送参数：`, payload.data);
        })
    }

    // 单独注册回调
    addListener(params, callback, vm) {
        Emitter.addListener(params.topic, callback, vm, params);
    }
}