export default new class {
    constructor() {
            this.listeners = []
            setInterval(() => {
                let now = new Date().getTime();
                this.listeners.forEach((item, key) => {
                    if (item.params.delete !== undefined && item.params.delete === false) {
                        return;
                    } else {
                        if (item.time) {
                            if (now - item.time > 45 * 1000) {
                                // console.log(`${item.params.pubTopic} 请求超时：${(now - item.time) / 1000}s, ${JSON.stringify(item.params.payload)}`);
                                try {
                                    // item.callback.call(item.vm, key, {
                                    //   code: 999,
                                    //   message: "请求超时",
                                    //   data: []
                                    // });
                                } catch (e) {
                                    e;
                                }
                                this.listeners.splice(key, 1);
                            }
                        } else {
                            this.listeners.splice(key, 1);
                        }
                    }

                })
            }, 20 * 1000)
        }
        // 注册事件
    addListener(label, callback, vm, params) {

            if (typeof callback === 'function') {
                let ind = this.listeners.findIndex(item => item.label === 'robot_com_bind')
                let index = this.listeners.findIndex(item => item.label === 'cardmsg')
                let longin_index = this.listeners.findIndex(item => item.label === 'login')
                if (ind !== -1 && label == 'robot_com_bind') {
                    this.listeners.splice(ind, 1)
                }
                if (index !== -1 && label == 'cardmsg') {
                    this.listeners.splice(index, 1)
                }
                if (longin_index !== -1 && label == 'login') {
                    this.listeners.splice(longin_index, 1)
                }
                this.listeners.push({
                    callback,
                    label,
                    vm,
                    time: new Date().getTime(),
                    params
                });
            }
        }
        // 删除事件
    removeListener(label, callback, vm) {
            //console.log("删除回调", label, callback, vm);
            let index = null
            let obj = this.listeners.filter((item, i) => {
                if (item.label == label) {
                    index = i
                }
                return item.label == label
            })[0]
            if (obj && obj.callback == callback && obj.vm == vm) {
                if (obj.params.delete == undefined || obj.params.delete) { // delete没有或者为true
                    this.listeners.splice(index, 1);
                }
            }
        }
        // 根据事件的key调用回调
    emit(label, ...args) {
        let listener = this.listeners.filter(item => item.label === label)[0];
        if (listener) {
            listener.callback.call(listener.vm, label, ...args);
            this.removeListener(label, listener.callback, listener.vm);
            // if (!["robot_com_bind", "robot_com_qr", "robot_com_logout"].includes(label)) {
            // }
        }
    }
}