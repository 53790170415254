<template>
  <common-layout>
    <!-- <div class="top">
      <div class="header">
        <img alt="logo" class="logo" src="@/assets/img/logo.png" />
        <span class="title">{{systemName}}</span>
      </div>
    </div> -->
    <div class="login">
      <a-form @submit="onSubmit" :form="form">
        <a-tabs
          size="large"
          :tabBarStyle="{ textAlign: 'center' }"
          style="padding: 0 2px"
        >
          <a-tab-pane tab="账户密码登录" key="1">
            <a-alert
              type="error"
              :closable="true"
              v-if="error"
              :message="error"
              @close="onClose"
              showIcon
              style="margin-bottom: 24px"
            />
            <a-form-item>
              <a-input
                autocomplete="autocomplete"
                size="large"
                placeholder="请输入账户"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入账户名',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="user" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                size="large"
                placeholder="请输入密码"
                autocomplete="autocomplete"
                type="password"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入密码',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="lock" />
              </a-input>
            </a-form-item>
          </a-tab-pane>
          <!-- <a-tab-pane tab="手机号登录" key="2">
            <a-form-item>
              <a-input size="large" placeholder="mobile number" >
                <a-icon slot="prefix" type="mobile" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-row :gutter="8" style="margin: 0 -4px">
                <a-col :span="16">
                  <a-input size="large" placeholder="captcha">
                    <a-icon slot="prefix" type="mail" />
                  </a-input>
                </a-col>
                <a-col :span="8" style="padding-left: 4px">
                  <a-button style="width: 100%" class="captcha-button" size="large">获取验证码</a-button>
                </a-col>
              </a-row>
            </a-form-item>
          </a-tab-pane> -->
        </a-tabs>
        <!-- <div>
          <a-checkbox :checked="true">自动登录</a-checkbox>
          <a style="float: right">忘记密码</a>
        </div> -->
        <a-form-item>
          <a-button
            :loading="logging"
            style="width: 100%; margin-top: 24px"
            size="large"
            htmlType="submit"
            type="primary"
            >登录</a-button
          >
        </a-form-item>
        <!-- <div>
          <router-link style="float: right" to="/dashboard/workplace" >注册账户</router-link>
        </div> -->
      </a-form>
    </div>
  </common-layout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout";
import Observer from "@/utils/Observer";
import Vue from "vue";
import {
    mapState
  } from 'vuex'
export default {
  name: "Login",
  components: { CommonLayout },
  data() {
    return {
      logging: false,
      error: "",
      form: this.$form.createForm(this),
      connection: {
          host: process.env.VUE_APP_HOST,
          port: process.env.VUE_APP_PORT,
          qos:1,
          endpoint: '/mqtt',
          clean: true, // 保留会话
          connectTimeout: 4000, // 超时时间
          reconnectPeriod: 4000, // 重连时间间隔
          // 认证信息
          clientId: '',
          company:'',
          token:"jayden",
          username: '',
          password:'',
      },
    };
  },
  provide() {
    return {
      appData: this,
    };
  },
  watch: {},
  computed: {
    systemName() {
      return this.$store.state.setting.systemName;
    },
    account() {
      return this.$store.state.account.user;
    },
    ...mapState(['mqttInit'])

  },
  watch:{
      mqttInit:{
          handler(val){
              if(val){
                  this.getLabel()
                  this.getRobots()
              }
          },
          immediate:true
      }
    },
  created() {
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          const name = this.form.getFieldValue("name");
          const password = this.form.getFieldValue("password");
          let data = {"account":name,"password":password}
          this.logging = true
          fetch('scrm/auth/login',{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
          　　}).then(response =>{
                return response.json()
              }).then(json => {
                console.log(json)
                
                if(json.success){
                  this.connection.clientId = name
                  this.connection.username = json.res.company
                  this.connection.password = json.res.password
                  lockr.set('clientId',name)
                  lockr.set('robots',json.res.robots)
                  this.$store.commit('syncUpdateCompany', json.res.company)
                  this.$store.commit('syncUpdateCompanyName', json.res.com_name)
                  this.$store.commit('syncUpdateUser', {...data,_id:json.res._id})
                  this.connection.company = json.res.company
                  lockr.set('company',json.res.company)
                  let observer = new Observer(this.connection);
                  Vue.prototype.$mqtt = observer;
                  this.logoutListener()
                  
                  this.$message.success('登录成功,即将跳转')
                  setTimeout(()=>{
                      this.$router.push('/robot')
                  },1000)
                  
                }else{
                  this.$message.error('账号或密码错误！！！')
                  this.logging = false
                }
              })
        }
      });
    },
    getLabel(){
        let payload = {
            msgId:'searchTag',
            data:{
                fetch:1
            }
        }
        this.$mqtt.doPublish(
            {
            pubTopic: "scrm/wxapi/searchTag",
            payload,
            },
            (topic, data) => {
              if(data.success){
                let tagList = data.res
                let arr = []
                tagList.forEach(item=>{
                  if(arr.findIndex(obj=>obj.id==item.group_id)==-1){
                    arr.push({id:item.group_id,tag_id:item.group_id,name:item.group_name,children:[]})
                  }
                })
                tagList.forEach(item=>{
                  let ind = arr.findIndex(obj=>obj.id==item.group_id)
                  if(ind!=-1){
                    arr[ind].children.push({id:item.label_id,name:item.label_name,tag_id:item.tag_id})
                  }
                })
                this.$store.commit('syncUpdateTag',{tagList:tagList,tagTree:arr})
                // console.log(arr)
              }
                
            },
            this
        );
    },
    getRobots(){
      let payload = {
            msgId:'searchAllUserByPage',
            data: {
                pageSize:100,
                pageNum:1,

            },
        };
        this.$mqtt.doPublish(
            {
            pubTopic: "scrm/weUser/searchUserByPage",
            payload,
            },
            (topic, data) => {
              if(data.code===200){
                  this.$store.commit('syncUpdateRobots',data.data.res)
              }
            
            },
            this
        );
    },
    logoutListener(){
      this.$mqtt.addListener(
        {
          topic: "logout",
          delete: false,
        },
        () => {
          this.$message.warning('您的账号已在其他机器登陆')
          setTimeout(() => {
            location.reload()
          }, 500);
        },
        this
      );
    },
    getRoutes() {
      let payload = {
        clientId: this.appData.connection.clientId,
      };
      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/menu/getAuthByToken",
          payload,
        },
        (topic, data) => {
          if (data.code !== 200) {
            this.error = data.message;
            this.logging = false;
            return;
          }
          let routes = data.data;
          data.data.unshift({ menu_code: "home" });
          function cover(arr) {
            arr.forEach((item) => {
              item.router = item.menu_code;
              if (item.sub && item.sub.length) {
                item.children = item.sub;
                cover(item.sub);
              }
            });
          }
          cover(routes);
          let rconfig = {
            router: "root",
            children: routes,
          };
          this.$router.push("/home");
        }
      );
    },
    onClose() {
      this.error = false;
      this.logging = false;
    },
  },
};
</script>

<style lang="less" scoped>
.common-layout {
  .top {
    text-align: center;
    .header {
      height: 44px;
      line-height: 44px;
      a {
        text-decoration: none;
      }
      .logo {
        height: 44px;
        vertical-align: top;
        margin-right: 16px;
      }
      .title {
        font-size: 33px;
        font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
          sans-serif;
        font-weight: 600;
        position: relative;
        top: 2px;
      }
    }
    .desc {
      font-size: 14px;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }
  .login {
    width: 408px;
    height: 350px;
    padding: 20px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    @media screen and (max-width: 576px) {
      width: 95%;
    }
    @media screen and (max-width: 320px) {
      .captcha-button {
        font-size: 14px;
      }
    }
    .icon {
      font-size: 24px;
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;
    }
  }
}
</style>
