<template>
  <div class="common-layout">
    <div class="content"><slot></slot></div>
    <!-- <page-footer :link-list="footerLinks" :copyright="copyright"></page-footer> -->
  </div>
</template>

<script>
// import PageFooter from '@/layouts/footer/PageFooter'

export default {
  name: 'CommonLayout'
}
</script>

<style scoped lang="less">
.common-layout{
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  background-image: url('../assets/back.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .content{
    padding: 32px 0;
    flex: 1;
    @media (min-width: 768px){

      padding: 200px 0 24px;
    }
  }
}
</style>
