var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('common-layout',[_c('div',{staticClass:"login"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-tabs',{staticStyle:{"padding":"0 2px"},attrs:{"size":"large","tabBarStyle":{ textAlign: 'center' }}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"账户密码登录"}},[(_vm.error)?_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","closable":true,"message":_vm.error,"showIcon":""},on:{"close":_vm.onClose}}):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入账户名',
                      whitespace: true,
                    } ],
                } ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入账户名',\n                      whitespace: true,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"autocomplete":"autocomplete","size":"large","placeholder":"请输入账户"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入密码',
                      whitespace: true,
                    } ],
                } ]),expression:"[\n                'password',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入密码',\n                      whitespace: true,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"size":"large","placeholder":"请输入密码","autocomplete":"autocomplete","type":"password"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1)],1)],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%","margin-top":"24px"},attrs:{"loading":_vm.logging,"size":"large","htmlType":"submit","type":"primary"}},[_vm._v("登录")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }